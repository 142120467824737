import { render, staticRenderFns } from "./FeatureView.vue?vue&type=template&id=57ac273b&"
import script from "./FeatureView.vue?vue&type=script&lang=js&"
export * from "./FeatureView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3849713675/src/decisionvue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57ac273b')) {
      api.createRecord('57ac273b', component.options)
    } else {
      api.reload('57ac273b', component.options)
    }
    module.hot.accept("./FeatureView.vue?vue&type=template&id=57ac273b&", function () {
      api.rerender('57ac273b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FeatureView.vue"
export default component.exports