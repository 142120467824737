<template>
  <div>
    <HeaderComponent>
    </HeaderComponent>
    <cookie-notice></cookie-notice>
    <header class="hc-heading hc-heading--page">
      <h2 class="hc-heading__text">Profile</h2>
      <div class="hc-actions">
        <button class="hc-btn js-toggle-errors" title="Exit" @click="exit"><i class="fas fa-window-close"></i></button>
      </div>
    </header>
    <div class="profileTabs">
      <!-- PERSONAL TAB -->
      <div class="profileTab">
        <input type="radio" id="tab-1" name="tab-group-1" :checked="showPersonalTab" @click="showNotificationsTab=false; showPersonalTab=true; showPreferencesTab=false;">
        <label class="profileTabLabel" for="tab-1">Personal</label>
        <div class="profileTabContent" v-if="showPersonalTab">
          <div class="profileSettings">
            <div v-for="(settingValue, settingKey) in personalSettings" :key="settingKey">
              <div class="flex-row flex-row--align-end flex-row--margin">
                <div>
                  <label>{{ settingKey }}</label>
                  <input disabled :value="settingValue" class="wg-form-control u-width-300">
                </div>
              </div>
              <br/>
            </div>
            <button @click="manageAccount" type="button" class="profileButton"> Manage Account </button>
            <div v-if="!readOnly">
              <div class="hc-heading__text" style="margin-bottom: 10px"><br/>Admin:<br/></div>
              <button @click="manageClients" type="button" class="profileButton"> Manage Client Account </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Notifications TAB -->
      <div class="profileTab">
        <input type="radio" id="tab-2" name="tab-group-2" :checked="showNotificationsTab" @click="showPersonalTab=false; showNotificationsTab=true; showPreferencesTab=false;">
        <label class="profileTabLabel" for="tab-2">Notifications</label>
        <div class="profileTabContent" v-if="showNotificationsTab">
          <div class="hc-content">
            <div class="hc-content__config">
              <div class="flex-row flex-row--margin">
                <span>Notify me when hazards reach risk level</span>
                <select class="wg-form-control wg-form-control--auto" @change="processAlertLevelChange($event)">
                  <option :selected="hazardLevel === 3">3</option>
                  <option :selected="hazardLevel === 4">4</option>
                  <option :selected="hazardLevel === 5">5</option>
                </select>
              </div>
              <div class="flex-row flex-row--align-end flex-row--margin">
                <div>
                  <label>Email</label>
                  <input disabled :value="email" class="wg-form-control u-width-300">
                </div>
                <label class="wg-form__switch" for="emailNotifications">
                  <input type="checkbox" id="emailNotifications" v-model="sendEmail" name="emailNotifications" @click="processEmailToggle()">
                  <div class="wg-form__switch-slider"></div>
                </label>
              </div>
            <div class="flex-row flex-row--align-end flex-row--margin">
              <div>
                <label>SMS Number</label>
                <input :value="phone" class="wg-form-control u-width-300" @change="processSMSNumber($event)">
              </div>
                <label class="wg-form__switch" for="smsNotifications">
                  <input type="checkbox" id="smsNotifications" v-model="sendSms" name="smsNotifications" @click="processSmsToggle()">
                  <div class="wg-form__switch-slider"></div>
                </label>
              </div>
              <ValidationWarningComponent
                v-if="showValidationLabel"
                warning-label="Expected format: +10123456789"
              >
              </ValidationWarningComponent>
            </div>
          </div>
        </div>
      </div>

      <!-- Preferences Tab -->
      <div class="profileTab">
        <input type="radio" id="tab-3" name="tab-group-3" :checked="showPreferencesTab" @click="showPersonalTab=false; showNotificationsTab=false; showPreferencesTab=true;">
        <label class="profileTabLabel" for="tab-3">Preferences</label>
        <div class="profileTabContent" v-if="showPreferencesTab">
          <div class="hc-content">
            <div class="hc-content__config">
              <label> Favourite Sites: </label>

              <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only show favourite sites on map</span>
                 <label class="wg-form__switch" for="siteMapPreferences">
                  <input type="checkbox" :checked="favouriteSiteMapPreference === true" id="siteMapPreferences" name="siteMapPreferences" @click="addFavouriteSiteMapPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>
              <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only show favourite sites in visualizer</span>
                 <label class="wg-form__switch" for="siteVizualizerPreferences">
                  <input type="checkbox" :checked="favouriteSiteVizualizerPreference === true" id="siteVizualizerPreferences" name="siteVizualizerPreferences" @click="addFavouriteSiteVizualizerPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>

              <br>
              <label> Favourite Hazards: </label>
               <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only allow favourite hazards for sites on the map</span>
                 <label class="wg-form__switch" for="hazardMapPreferences">
                  <input type="checkbox" :checked="favouriteHazardMapPreference === true" id="hazardMapPreferences" name="hazardMapPreferences" @click="toggleFavouriteHazardMapPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>
              <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only allow favourite hazards in visualizer</span>
                 <label class="wg-form__switch" for="hazardVizualizerPreferences">
                  <input type="checkbox" :checked="favouriteHazardVizualizerPreference === true" id="hazardVizualizerPreferences" name="hazardVizualizerPreferences" @click="toggleFavouriteHazardVizualizerPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>
              <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only show risk maps for favourite hazards</span>
                 <label class="wg-form__switch" for="hazardRiskMapPreferences">
                  <input type="checkbox" :checked="favouriteHazardRiskMapPreference === true" id="hazardRiskMapPreferences" name="hazardRiskMapPreferences" @click="toggleFavouriteHazardRiskMapPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>
              <div class="flex-row flex-row--margin preferences-indent">
                 <span>Only allow alerts from favourite hazards</span>
                 <label class="wg-form__switch" for="hazardAlertPreferences">
                  <input type="checkbox" :checked="favouriteHazardAlertPreference === true" id="hazardAlertPreferences" name="hazardAlertPreferences" @click="toggleFavouriteHazardAlertPreference()">
                  <div class="wg-form__switch-slider"></div>
                 </label>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/Header.vue';
import ValidationWarningComponent from '../components/ValidationWarning.vue';
import CookieNotice from '../components/CookieNotice.vue';
const phoneNumberRegex = new RegExp(/(\+\d{11})/);
export default {
  components: { HeaderComponent, ValidationWarningComponent, CookieNotice },
  computed: {
    name() { return this.$store.getters.givenName; },
    surname() { return this.$store.getters.familyName; },
    email() { return this.$store.getters.email; },
    organizationId() { return this.$store.getters.organizationId; },
    personalSettings() {
      return {
        Name: this.name,
        Surname: this.surname,
        Email: this.email,
        Organization: this.organizationId
      }
    },
    hazardLevel() { return this.$store.getters.wxAlertHazardLevel; },
    favouriteSiteMapPreference() { return this.$store.getters.showOnlyFavouriteSitesOnMap; },
    favouriteSiteVizualizerPreference() { return this.$store.getters.showOnlyFavouriteSitesInVizualizer; },
    favouriteHazardMapPreference() { return this.$store.getters.showOnlyFavouriteHazardsOnMap; },
    favouriteHazardVizualizerPreference() { return this.$store.getters.showOnlyFavouriteHazardsInVizualizer; },
    favouriteHazardRiskMapPreference() { return this.$store.getters.showOnlyFavouriteHazardsRiskMaps; },
    favouriteHazardAlertPreference() { return this.$store.getters.showOnlyFavouriteHazardAlerts; },
    readOnly() { return this.$store.getters.readOnly; }
  },
  data() {
    return {
      phone: null,
      sendSms: this.$store.getters.wxAlertSendSms ? true : false,
      sendEmail: this.$store.getters.wxAlertSendEmail ? true : false,
      showPersonalTab: true,
      showPreferencesTab: false,
      showNotificationsTab: false,
      showValidationLabel: false
    }
  },
  async mounted() {
    // Set this to store on first load then only update store is sms is valid
    this.phone = this.$store.getters.smsNumber;
  },
  methods: {
    processAlertLevelChange(event) {
      const newValue = event.target.value;
      this.$store.dispatch('changeAlertHazardLevel', {
        hazardLevel: parseInt(newValue)
      });
    },
    processEmailToggle() {
      this.$store.dispatch('toggleEmailNotifications', !this.sendEmail);
    },
    processSmsToggle() {
      this.$store.dispatch('toggleSmsNotifications', !this.sendSms);
    },
    processSMSNumber(event) {
      const phoneNumber = event.target.value;
      this.phone = phoneNumber;
      if (phoneNumber.match(phoneNumberRegex)) {
        this.showValidationLabel = false;
        return this.$store.dispatch('changeSmsNumber', phoneNumber);
      }

      // If empty - clear phone number
      if (phoneNumber !== '') {
        this.showValidationLabel = true;
      } else {
        this.$store.dispatch('changeSmsNumber', undefined);
      }
    },
    addFavouriteSiteMapPreference() {
      this.$store.dispatch('toggleSiteMapPreference', !this.favouriteSiteMapPreference);
    },
    addFavouriteSiteVizualizerPreference() {
      this.$store.dispatch('toggleSiteVizualizerPreference', !this.favouriteSiteVizualizerPreference);
    },
    toggleFavouriteHazardMapPreference() {
      this.$store.dispatch('toggleHazardMapPreference', !this.favouriteHazardMapPreference);
    },
    toggleFavouriteHazardVizualizerPreference() {
      this.$store.dispatch('toggleHazardVizualizerPreference', !this.favouriteHazardVizualizerPreference);
    },
    toggleFavouriteHazardRiskMapPreference() {
      this.$store.dispatch('toggleHazardRiskMapPreference', !this.favouriteHazardRiskMapPreference);
    },
    toggleFavouriteHazardAlertPreference() {
      this.$store.dispatch('toggleHazardAlertPreference', !this.favouriteHazardAlertPreference);
    },
    manageAccount() {
      window.open('https://auth.decisionvue.net/Account/Manage');
    },
    manageClients() {
      if (this.readOnly) {
        return;
      }
      window.open('https://auth.decisionvue.net/t/' + this.organizationId + '/Users/');
    },
    exit() {
      this.$router.push({ name: 'lastRouteFullPath' });
    }
  }
}
</script>

